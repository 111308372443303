import Vue from 'vue';

// axios
import axios from 'axios';
import { Crypto } from './crypto';

const BASE_URL = process.env.VUE_APP_BASE_URL_OSIRIS;

const apiKey = sessionStorage.getItem('api_key');
const accessToken = sessionStorage.getItem('access_token');

const bearerToken = `bearer ${accessToken}`;

const apiKeyDecrypted = apiKey ? Crypto.decrypt(apiKey) : null;

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        api_key: apiKeyDecrypted,
        authorization: bearerToken,
    },
    timeout: 0,
});

Vue.prototype.$http = api;

export default api;
