import merchantRouter from './merchant';
import consultRouter from './consult';
import optInRouter from './optIn';
import endorsementRouter from './endorsement';
import urRouter from './ur';
import configFee from './configFee';

const router = [
    ...merchantRouter,
    ...consultRouter,
    ...optInRouter,
    ...endorsementRouter,
    ...urRouter,
    ...configFee,
].map(item => ({
    ...item,
    meta: {
        requiresAuth: true,
    },
}));

export default router;
