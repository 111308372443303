import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import creditPlatform from './credit-platform';
import ur from './ur';
import user from './user';
import register from './register';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        creditPlatform,
        ur,
        user,
        register,
    },
    strict: process.env.DEV,
});
