export default {
    namespaced: true,
    state: {
        ur: {},
    },
    getters: {
        ur(state) {
            return state.ur;
        },
    },
    mutations: {
        UPDATE_SELECTED_UR(state, payload) {
            state.ur = payload;
        },
    },
};
