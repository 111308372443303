import { Crypto } from '@/libs/crypto';

export default {
    namespaced: true,
    state: {
        user: {
            id: sessionStorage.getItem('id') ? +Crypto.decrypt(sessionStorage.getItem('id')) : '',
            groupId: sessionStorage.getItem('r_') ? JSON.parse(Crypto.decrypt(sessionStorage.getItem('r_'))).group.id : '',
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
    },
    mutations: {
        UPDATE_USER(state, payload) {
            state.user = payload;
        },
    },
};
