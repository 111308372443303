const consultRouter = [
    {
        path: '/plataforma-credito/consulta/:id',
        name: 'credit-platform-view-consult',
        component: () => import('@/views/pages/CreditPlatform/Consult/ViewConsult.vue'),
    },

    {
        path: '/plataforma-credito/:register/optin/:optin/agenda',
        name: 'credit-platform-new-consult',
        component: () => import('@/views/pages/CreditPlatform/Consult/NewConsult.vue'),
    },
];
export default consultRouter;
