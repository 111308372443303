import { Crypto } from '@/libs/crypto';

export default {
    namespaced: true,
    state: {
        merchant: sessionStorage.getItem('optin') ? JSON.parse(Crypto.decrypt(sessionStorage.getItem('optin'))) : '',
    },
    getters: {
        merchant(state) {
            return state.merchant;
        },
    },
    mutations: {
        UPDATE_SELECTED_MERCHANT(state, payload) {
            state.merchant = payload;
        },
    },
};
