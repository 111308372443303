import Vue from 'vue';
import VueRouter from 'vue-router';

import creditPlatformRouter from './creditPlatform/creditPlatform';
import accessRouter from './access/access';
import extractRouter from './extract/extract';
import ProtectedRouter from '@/auth/useAdmin';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/pages/Home.vue'),
        },
        ...creditPlatformRouter,
        ...accessRouter,
        ...extractRouter,
        {
            path: '/registradoras',
            name: 'registers',
            component: () => import('@/views/pages/Registers/Register.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/perfil',
            name: 'profile',
            component: () => import('@/views/pages/Profile.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/criar-usuario',
            name: 'create-user',
            component: () => import('@/views/pages/Users/CreateUser.vue'),
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            },
        },
        {
            path: '/editar-usuario',
            name: 'edit-user',
            component: () => import('@/views/pages/Users/EditUser.vue'),
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            },
        },
        {
            path: '/',
            name: 'credit-platform-home',
            component: () => import('@/views/pages/MerchantsAndContracts.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const { requiresAdmin } = to.matched[0].meta;
        ProtectedRouter.execute(to, from, next, requiresAdmin);
    } else { next(); }
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
