import { Crypto } from '@/libs/crypto';

export default {
    namespaced: true,
    state: {
        register: sessionStorage.getItem('register') ? JSON.parse(Crypto.decrypt(sessionStorage.getItem('register'))) : [],
    },
    getters: {
        register(state) {
            return state.register;
        },
    },
    mutations: {
        UPDATE_USER_REGISTER(state, payload) {
            state.merchant = payload;
        },
    },
};
