const { default: OsirisService } = require('./BaseOsirisService');

const BASE_USER_URL = 'user';

class UserSevice extends OsirisService {
    async store({ name, email, permissions }) {
        try {
            return this.api.post('/user', { name, email, permissions });
        } catch ({ response }) {
            return response;
        }
    }

    async update(id, data) {
        try {
            return this.api.put(`/user/${id}`, data);
        } catch ({ response }) {
            return response;
        }
    }

    async show(id) {
        try {
            return await this.api.get(`${BASE_USER_URL}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get(BASE_USER_URL);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new UserSevice();
