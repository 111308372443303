import CryptoJS from 'crypto-js';

const secretKey = process.env.VUE_APP_SECRET_KEY;

export class Crypto {
    static encrypt(value) {
        return CryptoJS.AES.encrypt(String(value), secretKey).toString();
    }

    static decrypt(value) {
        const bytes = CryptoJS.AES.decrypt(value, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
