const extract = [
    {
        path: '/extrato',
        name: 'extract',
        component: () => import('@/views/pages/Extract/Extract.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/taxas',
        name: 'rates',
        component: () => import('@/views/pages/Rates/SelfRates.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/taxas-padrao',
        name: 'rates-default',
        component: () => import('@/views/pages/Rates/DefaultRates.vue'),
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
];

export default extract;
