const router = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/access/Login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/criar-senha/:token',
        name: 'create-password',
        component: () => import('@/views/pages/access/CreatePassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/esqueci-senha',
        name: 'forgot-password',
        component: () => import('@/views/pages/access/ForgotPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/resetar-senha/:token',
        name: 'reset-password',
        component: () => import('@/views/pages/access/ResetPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
];

export default router;
