const endorsementRouter = [
    {
        path: '/plataforma-credito/averbacao',
        name: 'credit-platform-endorsement',
        component: () => import('@/views/pages/CreditPlatform/Endorsement/pages/New/NewEndorsement.vue'),
    },
    {
        path: '/plataforma-credito/averbacao/:id',
        name: 'credit-platform-consult',
        component: () => import('@/views/pages/CreditPlatform/Endorsement/pages/Update/EditEndorsement.vue'),
    },
    {
        path: '/plataforma-credito/averbacao/rascunho/:id',
        name: 'credit-platform-consult',
        component: () => import('@/views/pages/CreditPlatform/Endorsement/EditEndorsementDraft.vue'),
    },
];
export default endorsementRouter;
