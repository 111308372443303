const consultRouter = [
    {
        path: '/plataforma-credito/optin',
        name: 'credit-platform-optin',
        component: () => import('@/views/pages/CreditPlatform/OptIn/pages/NewOptIn.vue'),
    },
    {
        path: '/plataforma-credito/optin/:id',
        name: 'edit-platform-optin',
        component: () => import('@/views/pages/CreditPlatform/OptIn/pages/UpdateOptIn.vue'),
    },
    {
        path: '/plataforma-credito/optin/:id/agenda',
        name: 'platform-optin-schedule',
        component: () => import('@/views/pages/CreditPlatform/OptIn/pages/Schedule.vue'),
    },
];

export default consultRouter;
