/* eslint-disable class-methods-use-this */
import { Crypto } from '@/libs/crypto';
import UserService from '@/services/osiris/UserService';

class ProtectedRouter {
    async userIsAdmin() {
        const id = Crypto.decrypt(sessionStorage.getItem('id'));
        let permissions;
        try {
            const permissionsEncrypted = sessionStorage.getItem('permissions');
            const permissionsDecrypted = Crypto.decrypt(permissionsEncrypted);
            permissions = JSON.parse(permissionsDecrypted);
        } catch {
            const { data } = await UserService.show(id);
            permissions = data.permissions;
            sessionStorage.setItem('permissions', Crypto.encrypt(JSON.stringify(permissions)));
        }

        const adminPermission = 1;
        return permissions.some(item => item === adminPermission);
    }

    async userIsAuthenticated() {
        return sessionStorage.getItem('permissions') || sessionStorage.getItem('id');
    }

    async execute(to, from, next, routeIsAdmin) {
        if (!sessionStorage.getItem('access_token')) {
            next('/login');
        }
        const promises = [
            this.userIsAuthenticated(),
        ];

        if (routeIsAdmin) {
            promises.push(this.userIsAdmin());
        }

        const [userIsAuthenticated, userIsAdmin] = await Promise.all(promises);

        if (!userIsAuthenticated) {
            next('/login');
        } else if (!routeIsAdmin && userIsAuthenticated) {
            next();
        } else if (routeIsAdmin && !userIsAdmin) {
            next('/error-404');
        }

        next();
    }
}

export default new ProtectedRouter();
