import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueSweetalert2 from 'vue-sweetalert2';

import VueTheMask from 'vue-the-mask';

import money from 'vuejs-money';
import router from './router';
import store from './store';
import App from './App.vue';

import date from '@/filters/date';
import taxId from '@/filters/taxId';
import valueFilter from '@/filters/value';

import './global-components';
import '@/libs/portal-vue';
import '@/libs/toastification';

import inactivity from '@/mixins/inactivity';

Vue.use(VueSweetalert2);

Vue.filter('date', value => date(value));
Vue.filter('date_hour', value => date(value, 'dd/MM/yyyy hh:mm'));
Vue.filter('tax_id', value => taxId(value));
Vue.filter('value', value => valueFilter(value));
Vue.filter('percentage', value => `${value}%`);
Vue.filter('thousand', value => value.toLocaleString('pt-BR', { useGrouping: true }));

Vue.mixin(inactivity);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Mask Input
Vue.use(VueTheMask);

Vue.use(money);

Vue.mixin({
    data() {
        return {
            money: {
                decimal: '.',
                thousands: '',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    methods: {
        modalError(text, html = false) {
            if (html) {
                this.$swal({
                    icon: 'error',
                    html: text,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    buttonsStyling: false,
                });
            } else {
                this.$swal({
                    icon: 'error',
                    text,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    buttonsStyling: false,
                });
            }
        },

        modalSuccess(text, html = false) {
            if (html) {
                this.$swal({
                    icon: 'success',
                    html: text,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
            } else {
                this.$swal({
                    icon: 'success',
                    text,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
            }
        },

        async confirmAnAction(message) {
            try {
                const response = await this.$bvModal
                    .msgBoxConfirm(message, {
                        cancelVariant: 'outline-secondary',
                        centered: true,
                        okTitle: 'Sim',
                        cancelTitle: 'Não',
                    });
                return response;
            } catch {
                return false;
            }
        },

        isLoading(activateLoading, transparentLoading = false) {
            const loadingDiv = document.getElementById('loading-bg');

            if (transparentLoading) { loadingDiv.classList.add('transparent'); } else { loadingDiv.classList.remove('transparent'); }

            if (activateLoading) {
                loadingDiv.style.display = 'block';
                loadingDiv.style.position = 'fixed';
                loadingDiv.style.zIndex = 9999;
            } else { loadingDiv.style.display = 'none'; }
        },

        formatDateToInput(date) {
            const newDate = new Date(date);
            const day = newDate.getDate();
            const month = newDate.getMonth();
            const year = newDate.getFullYear();
            return `${year}-${String(month + 1).padStart(2, 0)}-${String(day).padStart(2, 0)}`;
        },

        getStoreMerchant() {
            return this.$store.getters['creditPlatform/merchant'];
        },

        objectIsEmpty(obj) {
            // eslint-disable-next-line no-restricted-syntax
            for (const prop in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(prop)) { return false; }
            }

            return true;
        },
    },
});

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
