const merchantRouter = [
    {
        path: '/contratos',
        name: 'credit-platform-endorsements',
        component: () => import('@/views/pages/Endorsement/Endorsement.vue'),
    },
    {
        path: '/plataforma-credito/estabelecimento/criar',
        name: 'credit-platform-merchant-create',
        component: () => import('@/views/pages/CreditPlatform/Merchants/CreateEdit/Create.vue'),
    },
    {
        path: '/plataforma-credito/estabelecimento/editar/:id',
        name: 'credit-platform-merchant-edit',
        component: () => import('@/views/pages/CreditPlatform/Merchants/CreateEdit/Edit.vue'),
    },
    {
        path: '/plataforma-credito/estabelecimento/detalhes/:id',
        name: 'credit-platform-merchant-details',
        component: () => import('@/views/pages/CreditPlatform/Merchants/Details/Details.vue'),
    },
];

export default merchantRouter;
